import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TablePagination,

  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';


import {
  Search as SearchIcon,
} from "react-feather";

import { NEONPV_CONFIG } from "config.js";

import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { updateMalfunctionMemberName,
  updateMalFunctionReportStartTime, updateMalFunctionReportEndTime, updateMalFunctionStatName,
  updateMalFunctionRegion, updateMalFunctionStatus } from "actions/query";


import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { malfunction_status_list, getMalfunctionStatusCode, getMalfunctionStatusLabel, USER_AUTH } from "common/constants";


const MalfunctionTable = (props) => {
  const { className, user, queryParamMalfunction, ...rest } = props;

  const classes = useStyles();

  const userAuth = useSelector((state) => state.user.user_auth);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [listData, setListData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const [memberName, setMemberName] = useState(userAuth !== USER_AUTH.ADMIN ? user.admin_name : '');
  const [reportStartTime, setReportStartTime] = useState(new Date((new Date()).valueOf() - (1000 * 60 * 60 * 24)));
  const [reportEndTime, setReportEndTime] = useState(new Date());

  const [queryStatId, setQueryStatId] = useState("");
  const [queryStatName, setQueryStatName] = useState("");
  const [queryRegion, setQueryRegion] = useState("");
  const [queryChargerId, setQueryChargerId] = useState("");

  const [selectedStatus, setSelectedStatus] = useState('');




  const searchData = (autoload = false, startTime, endTime, statId, statName, region, chargerId) => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/malFunction/queryMalfunctionList?`;

    var params = "";

    //회원사 계정이라면
    if (userAuth === USER_AUTH.MEMBER ) {
      params += "memberId=" + user.admin_id + "&";
    } else if (memberName) {
    //관리자 계정이라면
      params += "memberName=" + memberName + "&";
    }

    if(startTime){params += `startTime=${moment(startTime).format('YYYY-MM-DD 00:00')}&`}
    if(endTime){params += `endTime=${moment(endTime).format('YYYY-MM-DD 23:59')}&`}
    if(statId){params += `statId=${statId}&`}
    if(statName){params += `statName=${statName}&`}
    if(region){params += `region=${region}&`}
    if(chargerId){params += `chargerId=${chargerId}&`}

    url += params;
    console.log(memberName, url)
    fetch(url)
      .then((res) => res.json())
      .then((snapshot) => {
        var i = 1;
        var list = [];
        if (snapshot.resultData != null) {
          snapshot.resultData.forEach((doc) => {
            doc.no = i++;
            list.push(doc);
          });
        }
        setListData(list);

        if (autoload === false && list.length === 0){
          setDialogMessage('검색 결과가 없습니다.');
          setOpenDialog(true);
        }

        setPage(0);
      });
  };



  const handleSearch = (event) => {

    // setDialogMessage('검색어를 입력해 주세요. ');
    // setOpenDialog(true);
    searchData(false, reportStartTime, reportEndTime, queryStatId, queryStatName, queryRegion, queryChargerId);
  };

  const doKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      handleSearch();
    }
  }


  /**
   * 검색 정보 지우기
   * @param {*} event
   */
  const handleClear = (event) => {
    // props.updateMalFunctionReportStartTime(new Date((new Date()).valueOf() - (1000 * 60 * 60 * 24)));
    // props.updateMalFunctionReportEndTime(new Date());
    props.updateMalfunctionMemberName('');
    props.updateMalFunctionReportStartTime('');
    props.updateMalFunctionReportEndTime('');
    props.updateMalFunctionStatName("");
    props.updateMalFunctionRegion("");
    props.updateMalFunctionStatus("");

    setMemberName(userAuth !== USER_AUTH.ADMIN ? user.admin_name : '');

    setReportStartTime ( new Date((new Date()).valueOf() - (1000 * 60 * 60 * 24)));
    setReportEndTime( new Date() );

    setQueryStatName("");
    setQueryRegion("");
    setSelectedStatus("");
    setListData([]);
  };


  const handleQueryMemberNameChange = (event) => {
    event.persist();
    setMemberName(event.target.value);
  }

  const handleReportStartTimeChange = (date) => {
    setReportStartTime(date);
  };

  const handleReportEndtimeChange = (date) => {
    setReportEndTime(date);
  };

  const handleQueryStatIdChange = (event) => {
    event.persist();
    setQueryStatId(event.target.value);
  };

  const handleQueryStatNameChange = (event) => {
    event.persist();
    setQueryStatName(event.target.value);
  };

  const handleQueryRegionChange = (event) => {
    event.persist();
    setQueryRegion(event.target.value);
  };

  const handleQueryChargerIdChange = (event) => {
    event.persist();
    setQueryChargerId(event.target.value);
  }


  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };


  const startRow = (page * rowsPerPage);


  useEffect(() => {
    searchData(true,reportStartTime, reportEndTime, queryStatId, queryStatName, queryRegion, queryChargerId);

    if(userAuth === USER_AUTH.MEMBER) {
      setMemberName(user.admin_name);
    } else {
      setMemberName(queryParamMalfunction.queryMalfunctionMemberName);
    }

    if (queryParamMalfunction.queryMalfunctionReportStartTime) {
      setReportStartTime(queryParamMalfunction.queryMalfunctionReportStartTime);
    }
    if (queryParamMalfunction.queryMalfunctionReportEndTime) {
      setReportEndTime(queryParamMalfunction.queryMalfunctionReportEndTime);
    }

    setQueryStatName(queryParamMalfunction.queryMalfunctionStatName);
    setQueryRegion(queryParamMalfunction.queryMalfunctionRegion);
    setSelectedStatus(queryParamMalfunction.queryMalfunctionStatus);

    return () => { };
  }, []);


  return (

    <div>

      <Dialog open={openDialog} onClose={() => { setOpenDialog(false) }}>
        <DialogTitle>주의</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => { setOpenDialog(false) }}>닫기</Button>
        </DialogActions>
      </Dialog>


      <Card className={clsx(classes.root, className)} {...rest}>
        <Box m={1} p={2}>

          <Box mt={2} display="flex" alignItems="center" justifyContent="start">

            <TextField className={classes.queryField_small}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                    </InputAdornment>
                  )
                }}

                disabled={userAuth !== USER_AUTH.ADMIN}
                onChange={handleQueryMemberNameChange}
                label="운영회원사"
                placeholder="운영회원사"
                value={memberName}
                variant="outlined"
                onKeyUp={(ev) => doKeyPress(ev)}
              />

            {/* <Typography variant="h5" >신고일시</Typography> */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              
              <KeyboardDatePicker
                // locale="ko"
                // disableToolbar
                // margin="dense"
                // showTodayButton={true}
                autoOk
                variant="inline"
                id="date-picker-dialog"
                // label="조회 기준일"
                format="yyyy-MM-dd"
                fontSize="30px"
                label="신고일시(시작)"
                required
                value={reportStartTime}
                onChange={handleReportStartTimeChange}
                KeyboardButtonProps={{ 'aria-label': 'change date', }}
                className={classes.datepicker}
                InputProps={{
                  style: {
                    fontSize: 16,
                    height: 44
                  }
                }}
                style={{
                  width: 180,
                  marginLeft: 20,
                  marginRight: 20,
                }}
              />
              <Typography variant="h5">~</Typography>
              <KeyboardDatePicker
                // locale="ko"
                // disableToolbar
                // margin="dense"
                // showTodayButton={true}
                autoOk
                variant="inline"
                id="date-picker-dialog"
                // label="조회 기준일"
                format="yyyy-MM-dd"
                fontSize="30px"
                label="신고일시(종료)"
                required
                value={reportEndTime}
                onChange={handleReportEndtimeChange}
                KeyboardButtonProps={{ 'aria-label': 'change date', }}
                className={classes.datepicker}
                InputProps={{
                  style: {
                    fontSize: 16,
                    height: 44
                  }
                }}
                style={{
                  width: 180,
                  marginLeft: 20,
                  marginRight: 20,
                }}
              />
            </MuiPickersUtilsProvider>


            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              // style={{ width: 80 }}
              onChange={handleQueryStatIdChange}
              label="충전소ID"
              placeholder="충전소ID"
              value={queryStatId}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />

            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryStatNameChange}
              label="충전소"
              placeholder="충전소"
              value={queryStatName}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />
            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              // style={{ width: 80 }}
              onChange={handleQueryRegionChange}
              label="주소"
              placeholder="주소"
              value={queryRegion}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />

            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChargerIdChange}
              label="충전기 ID"
              placeholder="충전기 ID"
              value={queryChargerId}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />

            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.bulkAction}
              onClick={handleSearch}
            >
              검색
            </Button>

            <Button
              variant="outlined"
              size="large"
              className={classes.bulkAction}
              onClick={handleClear}
            >
              초기화
            </Button>
          </Box>
        </Box>

        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" width={COLUMN_WIDTH.CORPORATE_NAME}>충전소</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.CORPORATE_NAME}>충전소 ID</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.CORPORATE_NAME}>충전기 ID</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.CORPORATE_NAME}>부품</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.CORPORATE_NAME}>수리/교체</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.CORPORATE_NAME}>교체일자</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.REPORTER_TEL_NO}>고장신고내용</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {listData.slice(0 + startRow, rowsPerPage + (page * rowsPerPage)).map((module) => (
                    <TableRow className={classes.tableRow} >
                      <TableCell align="center" width={COLUMN_WIDTH.CORPORATE_NAME}><Typography variant="body2">{module.statName}</Typography></TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.CORPORATE_NAME}><Typography variant="body2">{module.statId}</Typography></TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.CORPORATE_NAME}><Typography variant="body2">{module.chargerId}</Typography></TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.CORPORATE_NAME}><Typography variant="body2">{module.part}</Typography></TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.CORPORATE_NAME}><Typography variant="body2">{module.type}</Typography></TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.CORPORATE_NAME}><Typography variant="body2">{module.reportDate ? moment(module.reportDate).format('YY-MM-DD HH:mm:ss') : ''}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.REPORT_REGION} style={{ wordBreak: "break-word"}}><Typography variant="body2">{module.report_content}</Typography></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>

        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={listData.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>

    </div>
  );
};

MalfunctionTable.propTypes = {
  className: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { updateMalfunctionMemberName,
      updateMalFunctionReportStartTime, updateMalFunctionReportEndTime, updateMalFunctionStatName,
      updateMalFunctionRegion, updateMalFunctionStatus},
    dispatch
  );
};

let mapStateToProps = (state) => {
  return {
    queryParamMalfunction: state.queryParamMalfunction,
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MalfunctionTable));



const COLUMN_WIDTH = {
  NO: '3%',
  REPORT_DATE: '3%', //10
  REPORTER: '3%', //15
  CORPORATE_NAME: '3%', //23
  REPORTER_TEL_NO: '10%', //30
}


const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // marginLeft: theme.spacing(1)
  },
  queryField: {
    flexBasis: 220,
    marginRight: 20,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
}));
